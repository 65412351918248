import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { colors } from "../theme";

const More = styled.span`
  display: inline-block;
  position: relative;
`;

const Tooltip = styled.div`
  border: 1px solid #d6d7d8;
  border-radius: 4px;
  background: white;
  box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.16);
  height: 148px;
  left: calc(50% - 89px);
  opacity: 0;
  position: absolute;
  text-align: left;
  top: -165px;
  transform: translate(-50%, 10px);
  transition: 0.15s linear;
  visibility: hidden;
  width: 236px;
  z-index: 10;

  @media (max-width: 767px) {
    left: 50%;
  }

  ${(props) =>
    props.showTooltip &&
    `
    opacity: 1;
    transform: translate(-50%, 0);
    visibility: visible;

    + button {
      transform: rotate(45deg);
    }
  `}

  :before {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #d6d7d8;
    bottom: -12px;
    content: "";
    height: 0;
    right: 16px;
    position: absolute;
    width: 0;
  }

  :after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    bottom: -10px;
    content: "";
    height: 0;
    right: 18px;
    position: absolute;
    width: 0;
  }

  @media (max-width: 767px) {
    :before {
      right: 106px;
    }

    :after {
      right: 108px;
    }
  }

  h1 {
    border-bottom: 1px solid #e9eef4;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 2px 9px;
    padding: 8px 10px;
    text-transform: uppercase;

    i {
      font-size: 1.2rem;
      margin-right: 4px;
    }
  }

  > div {
    display: flex;
    margin: 0 5px;

    div {
      width: 50%;
    }
  }

  a {
    color: ${colors.textColor};
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    transition: 0.3s;

    :hover {
      color: ${colors.primaryColor};
    }

    i {
      font-size: 2.2rem;
      top: 2px;
      position: relative;
    }
  }
`;

function Share({ text, facebookRounded, otherUrl }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const { pathname } = useLocation();
  const url = otherUrl || `${process.env.RAZZLE_HOSTNAME}${pathname}`;

  function toggleTooltip() {
    setShowTooltip((state) => !state);
  }

  return (
    <>
      <a
        href={encodeURI(`https://facebook.com/sharer/sharer.php?u=${url}`)}
        target="_blank"
        rel="noopener noreferrer"
        title="Facebook"
      >
        <i className={`fab fa-facebook${facebookRounded ? "" : "-f"}`} />
      </a>
      <a
        href={encodeURI(
          `https://twitter.com/intent/tweet/?text=${text} via @LeMediaTV&url=${url}`
        ).replace(/#/g, "%23")}
        target="_blank"
        rel="noopener noreferrer"
        title="Twitter"
      >
        <i className="fab fa-twitter" />
      </a>
      <More>
        <Tooltip showTooltip={showTooltip}>
          <h1>
            <i className="far fa-share" /> Partager sur :
          </h1>
          <div>
            <div>
              <a
                href={encodeURI(
                  `https://facebook.com/sharer/sharer.php?u=${url}`
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-fw fab fa-facebook" /> Facebook
              </a>
              <a
                href={encodeURI(
                  `https://twitter.com/intent/tweet/?text=${text} via @LeMediaTV&url=${url}`
                ).replace(/#/g, "%23")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-fw fab fa-twitter" /> Twitter
              </a>
              <a
                href={encodeURI(
                  `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${text}&summary=${text}&source=${url}`
                ).replace(/#/g, "%23")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-fw fab fa-linkedin" /> Linkedin
              </a>
            </div>
            <div>
              <a
                href={encodeURI(`whatsapp://send?text=${text} ${url}`).replace(
                  /#/g,
                  "%23"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-fw fab fa-whatsapp-square" /> WhatsApp
              </a>

              <a
                href={encodeURI(
                  `https://telegram.me/share/url?text=${text}&url=${url}`
                ).replace(/#/g, "%23")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-fw fab fa-telegram" /> Telegram
              </a>

              <a
                href={encodeURI(
                  `mailto:?subject=${text}&body=${text} : ${url}`
                ).replace(/#/g, "%23")}
                target="_self"
              >
                <i className="fa-fw fas fa-envelope-square" /> Email
              </a>
            </div>
          </div>
        </Tooltip>
        <button onClick={toggleTooltip}>+</button>
      </More>
    </>
  );
}

Share.propTypes = {
  text: PropTypes.string.isRequired,
  facebookRounded: PropTypes.bool,
};

export default Share;
