import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Img from "react-image";

import { colors } from "../../theme";
import VerticalShareBar from "../VerticalShareBar";
import Tags from "../Tags";
import photoPlaceholder from "../../assets/placeholder-avatar.svg";

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  text-align: center;
  width: 259px;

  @media (max-width: 1599px) {
    width: 218px;
  }

  img {
    border-radius: 50%;
    height: 164px;
    width: 164px;
  }

  p {
    font-size: 1.7rem;
    line-height: 2.8rem;
    margin-bottom: 1.7rem;

    a {
      color: ${colors.textColor};
      transition: 0.3s;

      :hover {
        color: ${colors.primaryColor};
      }
    }
  }

  p + div {
    margin-top: 30px;
  }
`;

const Left = styled.div`
  text-align: center;
  width: 184px;

  @media (max-width: 1599px) {
    width: 164px;
  }

  > h1 {
    color: black;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.4rem;
    margin: 17px 0 25px;

    a {
      color: black;
      text-decoration: none;
      transition: 0.3s;

      :hover {
        color: ${colors.primaryColor};
      }
    }
  }
`;

const DoublePhoto = styled.div`
  height: 242px;
  position: relative;

  img {
    height: 143px;
    position: absolute;
    width: 143px;

    &:first-of-type {
      left: 5px;
      bottom: 0;
      z-index: 2;
    }

    &:last-of-type {
      right: -5px;
      top: 0;
    }
  }
`;

const TriplePhoto = styled.div`
  height: 242px;
  position: relative;

  img {
    height: 130px;
    position: absolute;
    width: 130px;

    &:first-of-type {
      left: 0;
      bottom: 0;
      z-index: 2;
    }

    &:nth-of-type(2) {
      right: -10px;
      top: 65px;
      z-index: 1;
    }

    &:last-of-type {
      left: 0;
      top: 0;
    }
  }
`;

const Right = styled(VerticalShareBar)`
  margin-top: 187px;
`;

function AuthorCard({ className, authors, tags, title }) {
  let authorContent;
  if (!authors.length) {
    Sentry.captureMessage(`No author on ${title}`);
  } else if (authors.length === 1) {
    const author = authors[0];

    authorContent = (
      <>
        <Link to={`/auteurs/${author.slug}`}>
          <Img
            src={author.thumb_author_story_url}
            alt={author.screenname}
            loader={<img src={photoPlaceholder} alt={author.screenname} />}
            unloader={<img src={photoPlaceholder} alt={author.screenname} />}
          />
        </Link>
        <h1>
          Par <Link to={`/auteurs/${author.slug}`}>{author.screenname}</Link>
        </h1>
        <p>
          {author.description || (
            <>
              Vous pouvez retrouver tous les contenus de {author.screenname} en
              consultant <Link to={`/auteurs/${author.slug}`}>sa page</Link>.
            </>
          )}
        </p>
      </>
    );
  } else if (authors.length === 2) {
    authorContent = (
      <>
        <DoublePhoto>
          {authors.map((author) => (
            <Img
              key={author.screenname}
              src={author.thumb_author_story_url}
              alt={author.screenname}
              loader={<img src={photoPlaceholder} alt={author.screenname} />}
              unloader={<img src={photoPlaceholder} alt={author.screenname} />}
            />
          ))}
        </DoublePhoto>
        <h1>
          Par{" "}
          <Link to={`/auteurs/${authors[0].slug}`}>
            {authors[0].screenname}
          </Link>{" "}
          et{" "}
          <Link to={`/auteurs/${authors[1].slug}`}>
            {authors[1].screenname}
          </Link>
        </h1>
        <p>
          Retrouvez les contenus de ces auteurs&nbsp;:&nbsp;page de{" "}
          <Link to={`/auteurs/${authors[0].slug}`}>
            {authors[0].screenname}
          </Link>{" "}
          et page de{" "}
          <Link to={`/auteurs/${authors[1].slug}`}>
            {authors[1].screenname}
          </Link>
          .
        </p>
      </>
    );
  } else if (authors.length === 3) {
    authorContent = (
      <>
        <TriplePhoto>
          {authors.map((author) => (
            <Img
              key={author.screenname}
              src={author.thumb_author_story_url}
              alt={author.screenname}
              loader={<img src={photoPlaceholder} alt={author.screenname} />}
              unloader={<img src={photoPlaceholder} alt={author.screenname} />}
            />
          ))}
        </TriplePhoto>
        <h1>
          Par{" "}
          <Link to={`/auteurs/${authors[0].slug}`}>
            {authors[0].screenname}
          </Link>
          ,{" "}
          <Link to={`/auteurs/${authors[1].slug}`}>
            {authors[1].screenname}
          </Link>{" "}
          et{" "}
          <Link to={`/auteurs/${authors[2].slug}`}>
            {authors[2].screenname}
          </Link>
        </h1>
        <p>
          Retrouvez les contenus de ces auteurs&nbsp;:&nbsp;page de{" "}
          <Link to={`/auteurs/${authors[0].slug}`}>
            {authors[0].screenname}
          </Link>
          , page de{" "}
          <Link to={`/auteurs/${authors[1].slug}`}>
            {authors[1].screenname}
          </Link>{" "}
          et page de{" "}
          <Link to={`/auteurs/${authors[2].slug}`}>
            {authors[2].screenname}
          </Link>
          .
        </p>
      </>
    );
  } else {
    authorContent = (
      <>
        <TriplePhoto>
          {authors.map((author) => (
            <Img
              key={author.screenname}
              src={author.thumb_author_story_url}
              alt={author.screenname}
              loader={<img src={photoPlaceholder} alt={author.screenname} />}
              unloader={<img src={photoPlaceholder} alt={author.screenname} />}
            />
          ))}
        </TriplePhoto>
        <h1>
          Par{" "}
          <Link to={`/auteurs/${authors[0].slug}`}>
            {authors[0].screenname}
          </Link>
          ,{" "}
          <Link to={`/auteurs/${authors[1].slug}`}>
            {authors[1].screenname}
          </Link>
          ,{" "}
          <Link to={`/auteurs/${authors[2].slug}`}>
            {authors[2].screenname}
          </Link>{" "}
          et{" "}
          <Link to={`/auteurs/${authors[3].slug}`}>
            {authors[3].screenname}
          </Link>
        </h1>
        <p>
          Retrouvez les contenus de ces auteurs&nbsp;:&nbsp;page de{" "}
          <Link to={`/auteurs/${authors[0].slug}`}>
            {authors[0].screenname}
          </Link>
          , page de{" "}
          <Link to={`/auteurs/${authors[1].slug}`}>
            {authors[1].screenname}
          </Link>{" "}
          , page de{" "}
          <Link to={`/auteurs/${authors[2].slug}`}>
            {authors[2].screenname}
          </Link>{" "}
          et page de{" "}
          <Link to={`/auteurs/${authors[3].slug}`}>
            {authors[3].screenname}
          </Link>
          .
        </p>
      </>
    );
  }

  return (
    <Card className={className}>
      <Left>
        {authorContent}
        <Tags tags={tags} />
      </Left>
      <Right text={title} />
    </Card>
  );
}

AuthorCard.propTypes = {
  className: PropTypes.string,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      thumb_author_story_url: PropTypes.string,
      screenname: PropTypes.string.isRequired,
      description: PropTypes.string,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
};

export default AuthorCard;
