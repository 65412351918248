import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import chroma from "chroma-js";

import { colors } from "../theme";
import Lightbox from "./modal/Lightbox";
import bgQuote from "../assets/bg-blockquote.svg";

const Container = styled.div`
  color: black;
  line-height: 2.4rem;
  margin-bottom: 70px;

  h2 {
    font-size: 3.2rem;
    font-weight: 600;
    letter-spacing: 0.032rem;
    line-height: 4rem;
    margin: 26px 0 22px;
  }

  p {
    margin-bottom: 2.4rem;

    &.text-left {
      text-align: left;
    }

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }
  }

  a {
    color: ${colors.primaryColor};
    transition: 0.3s;

    :hover {
      color: ${colors.primaryHover};
    }
  }

  img {
    cursor: pointer;
    margin: 30px auto 10px;
    max-width: 100%;
  }

  li {
    margin-bottom: 2.4rem;
  }

  blockquote {
    background: url(${bgQuote}) no-repeat left top;
    color: ${colors.primaryColor};
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 3rem;
    margin: 44px 0 14px -18px;
    min-height: 155px;
    padding: 32px 67px 32px 85px;

    @media (max-width: 767px) {
      background-position: left top 10px;
      background-size: 30%;
      font-size: 2rem;
      margin: 24px 0;
      padding: 0 0 0 37px;
    }
  }

  figure {
    margin: 33px 0;
    text-align: center;

    img {
      margin: 0 auto;
    }

    &.flux-image-half img {
      width: 50%;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    figcaption {
      color: ${colors.textColor};
      font-size: 1.2rem;
      margin: 2px 0;
    }
  }

  .flux-story {
    margin: 60px;

    a {
      border-bottom: 1px solid ${colors.borderColor};
      border-top: 1px solid ${colors.borderColor};
      display: block;
      padding: 40px 30px;
      text-decoration: none;

      &:hover .flux-story-heading {
        color: ${colors.primaryColor};
      }
    }
  }

  .flux-story-heading {
    color: black;
    font-size: 1.7rem;
    font-weight: 600;
    margin: 10px 0 5px;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.2s;
  }

  .flux-story-excerp {
    color: ${colors.textColor};
    text-decoration: none;
  }

  span.obfuscated {
    background: ${colors.textColor};
    color: transparent;
  }

  .flux-table-responsive-wrapper {
    margin: 40px 0;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  thead th {
    text-transform: uppercase;
  }

  tbody tr {
    transition: 0.3s;
    white-space: nowrap;

    :nth-child(odd) {
      background: ${chroma(colors.borderColor).alpha(0.15)};
    }

    :hover {
      background: ${chroma(colors.borderColor).alpha(0.5)};
    }
  }

  th,
  td {
    font-family: Geomanist, sans-serif !important;
    font-size: 1.4rem;
  }

  th {
    font-weight: 600;
  }

  td {
    text-align: right;
  }
`;

function Wysiwyg({ children, className }) {
  return (
    <>
      <Helmet>
        <script
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        ></script>
      </Helmet>
      <Container id="wysiwyg" className={className}>
        <Lightbox>{children}</Lightbox>
      </Container>
    </>
  );
}

Wysiwyg.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Wysiwyg;
