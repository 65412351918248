import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../theme";

const Container = styled.div`
  margin: 0 -8px;

  a {
    background: ${colors.primaryColor};
    border-bottom-right-radius: 6px;
    color: white;
    display: inline-block;
    font-size: 1.2rem;
    margin: 0 8px 16px;
    padding: 5px 12px;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s linear;

    :hover {
      background: ${colors.primaryHover};
    }
  }
`;

function Tags({ tags }) {
  return (
    <Container>
      {tags.slice(0, 5).map(tag => (
        <Link key={tag.slug} to={`/tags/${tag.slug}`}>
          {tag.label}
        </Link>
      ))}
    </Container>
  );
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Tags;
