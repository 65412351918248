import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../theme";
import Share from "./Share";

const Container = styled.nav`
  text-align: center;
  width: 44px;

  :after {
    background: ${colors.borderColor};
    content: "";
    display: block;
    height: 147px;
    margin: 13px 0 0 20px;
    width: 1px;
  }

  > a,
  button {
    border: 1px solid #707070;
    border-radius: 50%;
    color: ${colors.textColor} !important;
    display: block;
    font-size: 2rem;
    height: 44px;
    line-height: 44px;
    margin-top: 18px;
    transition: 0.3s;
    width: 44px;

    :hover {
      border-color: ${colors.primaryColor};
      color: ${colors.primaryColor};
    }
  }

  > a:first-of-type {
    margin: 0;
  }

  > a + span {
    margin-top: 18px;
  }

  button {
    font-size: 2.8rem;
    font-weight: 100;
    line-height: 40px;
    margin: 0;
    text-decoration: none;
  }
`;

function VerticalShareBar({ className, text }) {
  return (
    <Container
      className={className}
      role="navigation"
      aria-label="Liens de partage sur les réseaux sociaux"
    >
      <Share text={text} />
    </Container>
  );
}

VerticalShareBar.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default VerticalShareBar;
