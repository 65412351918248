import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../theme";
import Share from "./Share";

const Container = styled.div`
  background: white;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${(props) => (props.withoutTitle ? "26px" : "33px")} 25px 21px 37px;
  position: relative;
  width: 776px;

  @media (max-width: 1023px) {
    width: 595px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: ${(props) => (props.withoutTitle ? "22px" : 0)} 25px 22px;
    width: calc(100% - 60px);
  }

  > a {
    background: ${(props) => props.titleColor || colors.primaryColor};
    color: white;
    font-size: 1.3rem;
    left: 50%;
    padding: 8px 27px;
    position: absolute;
    top: -14px;
    text-decoration: none;
    text-transform: uppercase;
    transform: translateX(-50%);
    white-space: nowrap;
    transition: 0.3s;

    @media (max-width: 767px) {
      line-height: 1.9rem;
      margin: 0 -25px 15px;
      padding: 8px 20px !important;
      position: static;
      transform: none;
      white-space: normal;
      width: calc(100% + 50px);
    }

    :hover {
      background: ${colors.primaryHover};
    }
  }

  > p {
    line-height: 2.4rem;
    width: 575px;

    a {
      color: ${colors.textColor};
      transition: 0.3s;

      :hover {
        color: ${colors.primaryColor};
      }
    }

    @media (max-width: 1023px) {
      width: 415px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

const ShareContainer = styled.div`
  flex-grow: 2;
  margin-top: 26px;
  max-width: 93px;

  @media (max-width: 1023px) {
    margin-top: 16px;
  }

  @media (max-width: 767px) {
    margin: 16px auto 0;
  }

  > div {
    text-transform: uppercase;

    i {
      font-size: 1.2rem;
      margin-right: 3px;
    }
  }

  > a,
  button {
    color: ${colors.textColor};
    display: inline-block;
    font-size: 2.4rem;
    margin: 0 4px;
    text-decoration: none;
    transition: 0.3s;

    :hover {
      color: ${colors.primaryColor};
    }
  }

  button {
    font-size: 3.4rem;
    font-weight: 100;
  }
`;

function Presentation({
  className,
  title,
  titleLink,
  titleColor,
  content,
  shareText,
}) {
  return (
    <Container
      className={className}
      titleColor={titleColor}
      withoutTitle={!title}
    >
      {title && <Link to={titleLink}>{title}</Link>}
      <p>{content}</p>
      <ShareContainer>
        <div>
          <i className="far fa-share" /> Partager
        </div>
        <Share facebookRounded text={shareText} />
      </ShareContainer>
    </Container>
  );
}

Presentation.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleLink: PropTypes.string,
  titleColor: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  shareText: PropTypes.string.isRequired,
};

export default Presentation;
