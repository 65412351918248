import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import Modal from "react-modal";
import throttle from "lodash.throttle";

import { colors, fadeIn } from "../../theme";

const appear = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0);
  } 
`;

const Container = styled.div`
  animation: ${appear} 0.4s;
  background: white;
  left: 50%;
  height: ${(props) => props.size.height}px;
  max-height: 100vh;
  max-width: 100%;
  padding: 30px;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.8s;
  width: ${(props) => props.size.width}px;

  img {
    animation: ${fadeIn} 1.2s;
    height: 100%;
    transition: 0.8s;
    width: 100%;
  }
`;

const Close = styled.button`
  border: 0;
  color: white;
  font-size: 2.3rem;
  height: 30px;
  position: absolute;
  right: 0;
  top: -29px;
  transition: 0.3s;
  width: 30px;

  :hover {
    background: white;
    color: ${colors.menuTitleColor};
  }
`;

function Lightbox({ children }) {
  const [image, setImage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [size, setSize] = useState({});
  const wrapperRef = useRef(null);

  useEffect(() => {
    wrapperRef.current
      .querySelectorAll(".flux-image a")
      .forEach((node) =>
        node.addEventListener("click", (e) => e.preventDefault())
      );

    wrapperRef.current.querySelectorAll("img").forEach((node) =>
      node.addEventListener("click", (e) => {
        setImage(e.target);
        setShowModal(true);
      })
    );
  }, [children]);

  useEffect(() => {
    setSize(getSize() || {});

    window.onresize = throttle(() => setSize(getSize() || {}), 400);

    function getSize() {
      if (image.naturalWidth) {
        let width = image.naturalWidth;
        let height = image.naturalHeight;
        const ratio = width / height;

        if (width > window.innerWidth + 60) {
          width = window.innerWidth - 60;
          height = width / ratio;
        }

        if (height > window.innerHeight + 60) {
          height = window.innerHeight - 60;
          width = height * ratio;
        }

        return {
          height,
          width,
        };
      }
    }
  }, [image]);

  function handleClose() {
    setShowModal(false);
  }

  return (
    <div ref={wrapperRef}>
      {children}
      <Modal
        isOpen={showModal}
        onRequestClose={handleClose}
        contentLabel={image.alt}
        style={{
          content: {
            background: "transparent",
            border: 0,
            borderRadius: 0,
            bottom: 0,
            left: 0,
            padding: 0,
            pointerEvents: "none",
            right: 0,
            top: 0,
          },
        }}
      >
        <Container size={size}>
          <Close title="Annuler" onClick={handleClose}>
            <i className="far fa-times" />
          </Close>
          {image && (
            <img
              src={image.src}
              alt={image.alt}
              width={size.width - 50}
              height={size.height - 50}
            />
          )}
        </Container>
      </Modal>
    </div>
  );
}

Lightbox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Lightbox;
