import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors, MainWrapper } from "../theme";
import bgLeftBase from "../assets/bg-mletter-base.png";
import bgLeftTop from "../assets/bg-mletter-top.svg";
import bgRightBase from "../assets/bg-support-base.png";
import bgRightTop from "../assets/bg-support-characters.svg";

const Container = styled.section`
  color: black;
  height: 487px;
  margin: 50px 0 36px;
  position: relative;

  @media (max-width: 1023px) {
    height: 455px;
  }

  @media (max-width: 767px) {
    height: 430px;
    margin-bottom: 75px;
  }

  :before {
    background: url(${bgLeftTop}) right 24px center, url(${bgLeftBase});
    background-repeat: no-repeat;
    background-size: auto, cover;
    content: "";
    display: block;
    height: 315px;
    position: absolute;
    width: calc(50% + 88px);

    @media (max-width: 1023px) {
      display: none;
    }
  }

  :after {
    background: url(${bgRightTop}) bottom left, url(${bgRightBase});
    background-repeat: no-repeat;
    background-size: contain, cover;
    bottom: 0;
    content: "";
    display: block;
    height: 315px;
    position: absolute;
    right: 0;
    width: calc(50% + 80px);

    @media (max-width: 1599px) {
      background-size: 592px, cover;
    }

    @media (max-width: 1023px) {
      background-size: contain, cover;
      width: 100%;
    }
  }
`;

const Wrapper = styled(MainWrapper)`
  display: flex;
  position: relative;
  z-index: 10;
`;

const Left = styled.div`
  padding-right: 80px;
  width: 50%;

  @media (max-width: 1023px) {
    display: none;
  }

  div {
    font-size: 2rem;
    letter-spacing: 0.06rem;
    line-height: 5.6rem;
    margin: 84px 61px 0 auto;
    text-transform: uppercase;
    width: 446px;

    @media (max-width: 1599px) {
      font-size: 1.8rem;
      line-height: 4.6rem;
      margin: 84px 42px 0 auto;
      width: 350px;
    }
  }

  strong {
    color: ${colors.primaryColor};
    font-weight: 600;
  }
`;

const Right = styled.div`
  padding-left: 138px;
  width: 50%;

  @media (max-width: 1599px) {
    padding-left: 0;
  }

  @media (max-width: 1023px) {
    padding: 0;
    width: 100%;
  }

  h1 {
    font-size: 4.8rem;
    font-weight: 100;
    letter-spacing: -0.07rem;
    line-height: 5.8rem;
    margin: 22px 0 94px 38px;
    text-transform: uppercase;

    @media (max-width: 1599px) {
      margin-left: 124px;
    }

    @media (max-width: 1023px) {
      margin: 0 0 74px;
      text-align: center;
    }

    @media (max-width: 767px) {
      font-size: 4rem;
      margin-bottom: 46px;
    }

    strong {
      display: block;
      font-size: 6.4rem;
      font-weight: 700;
      letter-spacing: 0.25rem;
      line-height: 5.1rem;

      @media (max-width: 767px) {
        font-size: 4.6rem;
        line-height: 3.3rem;
      }
    }
  }

  div {
    color: white;
    line-height: 2.5rem;
    text-align: center;
    width: 454px;

    @media (max-width: 1023px) {
      margin: auto;
    }

    @media (max-width: 767px) {
      width: 90%;
    }
  }

  @media (max-width: 433px) {
    p {
      max-width: 330px;
      margin: auto;
    }
  }

  nav {
    margin-top: 37px;
    width: 448px;

    @media (max-width: 1023px) {
      margin: 37px auto 0;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      margin-top: 25px;
    }

    a {
      background: ${colors.purple};
      color: white;
      display: block;
      font-weight: 600;
      margin: auto;
      padding: 15px 0 12px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.3s;
      width: 217px;

      @media (max-width: 767px) {
        margin: 0 auto 35px;
      }

      :hover {
        background: ${colors.primaryHover};
      }
    }
  }
`;

function JoinUs() {
  return (
    <Container>
      <Wrapper>
        <Left>
          <div>
            <p>
              Le Média n'appartient ni à l'état, ni à&nbsp;des milliardaires,{" "}
              <strong>
                il est financé uniquement par des citoyens comme vous.
              </strong>
            </p>
            <p>
              Il est en accès libre pour le plus grand nombre grâce à des
              citoyens qui contribuent tous les mois. Devenez Socio pour
              soutenir l'information indépendante !
            </p>
          </div>
        </Left>
        <Right>
          <h1>
            Rejoignez <strong>les Socios</strong>
          </h1>
          <div>
            <p>
              Afin de rester indépendant et en accès libre pour le plus grand
              nombre, et de continuer de vous proposer des enquêtes sur les
              magouilles de la Macronie et les arnaques des multinationales, des
              reportages de terrain au plus près de ceux qui luttent, des
              entretiens, débats et émissions comme on n'en voit plus à la télé,
              nous avons besoin de vous !
            </p>
          </div>
          <nav>
            <Link to="/soutien">Je soutiens Le Média</Link>
          </nav>
        </Right>
      </Wrapper>
    </Container>
  );
}

export default JoinUs;
